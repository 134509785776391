import React from 'react';
import Loading from '../../utils/Loader/Loading';
import useFetchData from '../../hooks/useFetch/useFetchData';
import Error from '../../utils/Error/Error';
import { Link } from 'react-router-dom';
import VisaCard from '../../components/VisaCard/VisaCard';
import Subscribe from '../../components/Subscribe/Subscribe';
import { img_url } from '../../utils/mainurl/mainurl';

const TeamMemberPage = () => {

    const { data, isLoading, isError } = useFetchData('team-members', 'team-members/getData/active');

    const team = data?.data || [];

    console.log("visa: ", team)

    if (isError) {
        return <div><Error isError={isError} /></div>;
    }

    if (isLoading) return <div>
        <Loading />
    </div>;

    return (
        <div>
            <>
                <>
                    <div
                        className="breadcrumb-section"
                        style={{
                            backgroundImage:
                                "linear-gradient(270deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, 0.3) 101.02%), url(../../assets/img/innerpage/inner-banner-bg.png)"
                        }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 d-flex justify-content-center">
                                    <div className="banner-content">
                                        <h1>Team Members</h1>
                                        <ul className="breadcrumb-list">
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li>Team Members</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Package Grid section */}
                    <div className="package-grid-section pt-120 mb-120">
                        <div className="container">
                            <div className="row gy-5 mb-70 ">
                                {
                                    team?.map((member, index) => <>
                                    <div className='col-lg-3 col-sm-1'>
                                        <TeamMemberCard member={member} />
                                    </div>
                                    </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    {/* End Package Grid section */}
                    {/* Start Banner3 section */}
                    <Subscribe />
                    {/* End Banner3 section */}
                    {/* Start Footer Section */}
                    {/* End Footer Section */}
                </>
            </>
        </div>
    );
};

const TeamMemberCard = ({ member }) => {
    return (
        <div className="swiper-slide"
            style={{
                // maxWidth: '266px',
                // maxHeight: '357px',
                // minWidth: '100%',
                // minHeight: '100%',
                // border: '1px solid red',
                // zIndex: '100'

            }}
        >
            <div className="teams-card">
                <img src="assets/img/home2/teams-card-bg.png" alt="" />
                <div className="teams-img">
                    <img src={`${img_url}${member?.image}`} alt=""
                        style={{
                            // maxWidth: '266px',
                            // maxHeight: '357px',
                            // minWidth: '100%',
                            // minHeight: '100%',
                            // border: '1px solid red',
                            // zIndex: '100'

                        }}
                    />
                </div>
                <div className="teams-content">
                    <h4
                        style={{
                            fontSize: "2px",
                            border: "1px solid red",
                        }}
                    >{member?.name}</h4>
                    <span>{member?.title}</span>
                </div>
                {
                    member?.social_icons?.length && <ul className="social-list">
                        {
                            member?.social_icons?.map((si, index) =>
                                <>
                                    <li>
                                        <a href={si?.link}>
                                            {/* <i className="bx bxl-instagram" /> */}
                                            <i className={si?.icon} />
                                        </a>
                                    </li>
                                </>
                            )
                        }
                        {/*
                        <li>
                            <a href="https://www.instagram.com/">
                                <i className="bx bxl-instagram" />
                            </a>
                        </li>
                         <li>
                            <a href="https://www.pinterest.com/">
                                <i className="bx bxl-pinterest-alt" />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    fill="currentColor"
                                    className="bi bi-twitter-x"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/">
                                <i className="bx bxl-facebook" />
                            </a>
                        </li> */}
                    </ul>
                }

            </div>
        </div>
    );
};

export default TeamMemberPage;